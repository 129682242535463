export enum RoutePath {
  Welcome = 'welcome',
  Home = 'home',
  GetStarted = 'get-started',
  Sites = 'sites',
  Runs = 'runs',
  Scenarios = 'scenarios',
  OndLibrary = 'ond-library',
  PanLibrary = 'pan-library',
  WtgLibrary = 'wtg-library',
  Root = '',
  Wildcard = '**',
  Duplicate = 'duplicate',
  Support = 'support',
}

export enum RouteId {
  SiteId = 'siteId',
  RunId = 'runId',
  ScenarioId = 'scenarioId',
}
